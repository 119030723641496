
import { defineComponent, reactive } from 'vue';
import { required, isSame } from '@/utils/validationFunctions';
import { useAlert } from '@/hooks/alert';
import { useRoute, useRouter } from 'vue-router';

import LoginInput from '@/components/LoginInput.vue';
import Form from "@/components/Form.vue";
import Loading from '@/components/Loading.vue';

import UsuarioService from '@/services/UsuarioService';
import { trackers } from '@/hooks/insights';

const RegisterPasswordView = defineComponent({
    components: { LoginInput, Form, Loading },
    setup() {
        // Hooks
        const alert = useAlert();
        const { replace, go, push } = useRouter();
        const route = useRoute();

        const state = reactive({
            password: '',
            confirmPassowrd: '',
            loading: false,
            validateOnInput: false
        });

        // Submeter formulário
        const submit = async (e: { isValid: boolean }) => {
            state.validateOnInput = true;
            if (e.isValid && !state.loading) {

                try {
                    state.loading = true;

                    if (route.name === "reset-password") {
                        const [request] = await UsuarioService.PostReset(route.params.cpf.toString());
                        request;
                    }

                    const [request] = await UsuarioService.PatchUpdatePassword({
                        cpf: route.params.cpf.toString(),
                        password: state.password
                    });
                    request
                        .then((resp) => {
                            trackers.registerPageView("Cadastro concluído", '/login')
                            alert({
                                title: "Cadastro concluído",
                                message: "Você será redirecionado para página de login!",
                                actions: [{ title: "OK", action: () => window.location.replace('/') }]
                            })
                        }).catch((error) => {
                            trackers.registerError('Erro ao cadastrar', error!.toString())
                            alert({
                                title: "Error",
                                message: error,
                            })
                        });

                } catch (error) {
                    trackers.registerError('Erro ao cadastrar', error!.toString())
                    alert({ message: error as string });
                }
                finally {
                    state.loading = false;
                }
            }
        };

        return { required, state, submit, isSame };
    }
});

export default RegisterPasswordView;
